.inputDiv {
  padding: 0 20%;
}

@media (max-width: 992px) {
  .inputDiv {
    padding: 0;
  }
}

.pageFooter {
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding-bottom: 1rem;
}

.footerLink {
  color: white;
  font-size: 1.05rem;
  text-decoration: none;
}

.footerLink:hover {
  color: #cccccc;
  text-decoration: underline;
}
