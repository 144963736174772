.socialLinks {
  position: absolute;
  bottom: 1%;
  font-size: 1.2rem;
  width: 100vh;
  text-align: center;

  a, span {
    color: #424242;
    text-decoration: none;
    margin-right: 10px;
    cursor: pointer;
  }

  a:hover, span:hover {
    color: #bebebe;
  }
}

.mintBtn {
  --bs-btn-color: #fff;
  --bs-btn-bg: #be3333;
  --bs-btn-border-color: #be3333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #af2b2b;
  --bs-btn-hover-border-color: #af2b2b;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #9a1f1f;
  --bs-btn-active-border-color: #9e3030;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #c94e4e;
  --bs-btn-disabled-border-color: #9a3f3f;

  --bs-btn-padding-y: 0.65rem;
  --bs-btn-padding-x: 1.2rem;
  --bs-btn-font-size: 1.45rem;
  --bs-btn-border-radius: 0.6rem;
}

.mintInfo {
  position: relative;
}

.mainContent {
  padding-top: 13rem;
}

@media (max-width: 992px) {
  .mintInfo {
    margin-top: 2rem;
  }

  .mintInfoMintDiv {
    position: inherit;
    margin-top: 50px;
  }

  .mainContent {
    padding-top: 25%;
  }
}

.mintInfoTitle {
  font-size: 3.3rem;
}

.mintInfoDescr {
  color: #555;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.mintInfoPrice {
  font-size: 1.4rem;
  margin-bottom: 0;
}

.mintInfoAmount {
  font-size: 1.2rem;
  margin-bottom: 0;
}





